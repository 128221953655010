.header{color: #fff;box-shadow: rgba(244, 118, 115, 0.3);box-shadow: 0px 4px 55.3px 0px rgba(65, 37, 235, 0.3);padding: 20px 30px;border-radius: 0px 0px 50px 50px;margin-bottom: 30px;}
.header-image{margin-top: 25px;text-align: center;}
.header span{color: #fff;}
.enter-challan-details{color: #fff;padding: 20px;}
.search-details {display: grid; grid-template-columns: auto auto;gap: 5px; border: 1px solid #FFF;border-radius: 10rem;padding: 10px;}
.search-details button{border-radius: 5rem;}
.search-details input {border: 0;margin: 0;text-transform: uppercase;font-size: 18px;}
.search-details input:focus {outline: none;}  
.history-header{box-shadow: 0px 4px 55.3px 0px rgba(65, 37, 235, 0.3);padding: 30px 30px 50px 30px;border-radius: 0px 0px 50px 50px;margin-bottom: 30px;}
.history-header span{margin-top: 50px;}
.vehicle-complete-info{color: #FFF;color: #FFF;text-align: center;display: grid;grid-template-columns: auto auto;align-items: center;justify-content: center;}
.vehicle-complete-info img{ margin-right: 20px;}
.history {color: #fff;padding: 20px;font-size: 20px;font-weight: 600;}
.challan-final-data{border: 1px solid #FFFFFF29;background: #454966;padding: 10px;color: #FFF;border-radius: 0px 0px 10px 10px;}
.challan-final-data p{display: grid;grid-template-columns: 100px auto; margin-bottom: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.16); padding: 0 0 5px 0;padding: 0 0 10px 0;}
.challan-final-data span{text-align: right;}
.challan-no{background: rgba(78, 83, 120, 1);padding: 10px 5px 10px 9px; color: #FFF;    border-top: 1px solid #FFFFFF29;border-radius: 10px 10px 0px 0px;}
.back-option{color: #FFF; margin-bottom: 50px;font-size: 18px;font-weight: 600;}
.vehicle-complete-info p{font-size: 12px;}
.vehicle-complete-info span{font-weight: 600;font-size: 24px;}
.header span{font-weight: 600; font-size: 18px;padding-left: 5px;cursor: pointer;}
.input-detail{padding: 20px;}