.header {padding: 20px 30px;border-radius: 0px 0px 40px 40px;background: linear-gradient(180deg, #000000 0%, #4B82F31A 100%);box-shadow: 0px 4px 30px 8px #00000040;}
.home-head {display: grid;grid-template-columns: 1fr 36px;justify-content: space-between;align-items: center;}
.profile-info {display: grid;grid-template-columns: auto auto;gap: 10px;align-items: center;justify-content: start;margin-top: 40px;}
.profile-info img {width: 60px;}
.profile-info p {font-size: 18px; margin-bottom: 0;}
.profile-info h3 {font-size: 27px;font-weight: 600;}

.home-content {padding: 20px;}
.banner-image {font-size: 50px;text-align: center;}
.banner-image img {width: 100%;margin-bottom: 20px;}
.home-heading {margin: 30px 0 10px 0;font-size: 20px;font-weight: 500;}
.service-cards {display: grid;grid-template-columns: 1fr 1fr;gap: 20px;}
.service-cards a {text-decoration: none;color: #FFF;}
.service-card {background: linear-gradient(180deg, #1832BE4D 0%, #48A0FA4D 100%);padding: 15px;border-radius: 0.5rem;}
.service-name {display: grid;grid-template-columns: auto auto;margin-top: 10px;justify-content: space-between;align-items: center;font-size: 16px;}
.nearby-places-container {display: grid;grid-template-columns: 1fr 1fr;background: #29487D;border-radius: 0.5rem;overflow: hidden;}
.nearby-places-box {padding: 30px 10px;text-align: center;border-color: #D4D4D421;border-style: solid;border-width: 0;}
.nearby-places-box:nth-child(1) {border-width: 0 1px 1px 0;}
.nearby-places-box:nth-child(2) {border-width: 0 0 1px 1px;}
.nearby-places-box:nth-child(3) {border-width: 1px 1px 0 0;}
.nearby-places-box:nth-child(4) {border-width: 1px 0 0 1px;}
.nearby-places-box p {font-size: 16px;margin-top: 10px;}
.check-fuel-prices {padding: 20px;background: #48A0FA4D;border-radius: 0.5rem;}
.state-name {background: #3781FF;padding: 15px;border-radius: 0.5rem;font-size: 16px;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.fuel-prices-container {display: grid;grid-template-columns: 1fr 1fr 1fr;margin-top: 30px;text-align: center;align-items: center;}
.fuel-prices-container > div {border-right: 1px solid #D4D4D421;}
.fuel-prices-container > div:last-child {border-right: 0;}
.fuel-price p {font-size: 16px;margin: 10px 0;}
.fuel-price h5 {font-size: 22px;font-weight: 500;}
.copyright {background-color: #3F4BA7;text-align: center;padding: 20px;font-weight: 500;}
.coming-soon{display: grid;grid-template-columns: auto auto;align-items: start;justify-content: space-between;}
.coming-soon p{border-radius: 1rem;padding: 5px 10px;font-size: 13px;background-color: #4d5eb575;color: #f64b3c;font-weight: 500;}