.rto-and-profile {display: grid;grid-template-columns: auto auto;align-items: center;justify-content: space-between;padding: 20px 30px;}
.profile-header {background: linear-gradient(180deg, #000000 0%, #4B82F333 100%);border-radius: 0px 0px 40px 40px;box-shadow: 0px 4px 30px 8px #00000040;}
.my-account-image {text-align: center;padding: 0 20px 0 20px;}
.profile-name {color: #FFF;font-size: 27px;font-weight: 600;text-align: center;padding: 0 20px 0 20px;}
.profile-info {padding: 20px;}
.profile-info a {text-decoration: none;color: #FFF;}
.profile-card {display: grid;grid-template-columns: 75px 1fr 40px;align-items: center;margin-bottom: 15px;border: 1px solid rgba(255, 255, 255, 0.08);padding: 10px;}
.profile-card span {font-weight: 500;}

.profile-editing {display: grid;grid-template-columns: 50px 20px 50px 20px; color: #FFF;padding: 20px;}
.profile-data {padding: 20px;}
.profile-data input {background-color: #FFFFFF30;border: none;outline: none;}
.profile-data label {color: #FFF;}
.profile-editing {padding: 50px 10px 50px 25px; display: grid;grid-template-columns: 30px 1fr 250px;box-shadow: 0px 4px 30px 8px #00000040;background: linear-gradient(180deg, #000000 0%, #4B82F31A 100%);border-radius: 0 0 40px 40px;}