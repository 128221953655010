/* Header CSS */
.header {padding: 20px 30px;border-radius: 0px 0px 40px 40px;background: linear-gradient(180deg, #000000 0%, #4B82F31A 100%);box-shadow: 0px 4px 30px 8px #00000040;}
.home-head {display: grid;grid-template-columns: 1fr 36px;justify-content: space-between;align-items: center;}
.profile-info {display: grid;grid-template-columns: auto auto;gap: 10px;align-items: center;justify-content: start;margin-top: 40px;}
.profile-info img {width: 60px;}
.profile-info p {font-size: 18px;margin-bottom: 0;}
.profile-info h3 {font-size: 27px;font-weight: 600;}
/* >>>>>>>>>>>>>>>>>>>>>> */

.history {padding: 20px;}
.vehicle-history{padding: 20px;display: grid;grid-template-columns: 65px 1fr 35px;color: #FFF;align-items: center;border: 1px solid #FFFFFF14;margin-bottom: 25px; cursor: pointer;}
.vehicle-history p{margin-bottom: 0;}
.back-option{color: #FFF;}
.history-header{background: linear-gradient(180deg, #000000 0%, rgba(75, 130, 243, 0.1) 100%);box-shadow: 0px 4px 30px 8px #00000040;padding: 30px 30px 30px 30px;border-radius: 0px 0px 50px 50px;}
.history-header span{margin-top: 50px;}
.vehicle-info{display: grid; margin-bottom: 35px;justify-content: center;}
.vehicle-info div{color: #FFF;text-align: center;margin-top: 20px;font-size: 20px;font-weight: 500;}
.vehicle-final-data{border: 1px solid #FFFFFF29;background: #454966;padding: 10px;color: #FFF;border-radius: 10px; margin-bottom: 20px;}
.vehicle-final-data p{border-bottom: 1px solid #FFFFFF29;padding: 10px;}
.vehicle-final-data span{float: right;}
.vehicle-complete-info{display: grid;grid-template-columns: auto auto;align-items: center;justify-content: center;gap: 25px;color: #FFF;margin-top: 40px;}
.vehicle-complete-info p{font-size: 24px; font-weight: 600;}
.vehicle-complete-info span{font-size: 12px;}
.challan-box{padding: 15px;}
.no-challan {text-align: center;color: #FFF;background: #454966;border-radius: 10px;padding: 15px;}
.no-challan p{font-size: 20px; margin-bottom: 25px;margin-top: 25px;}
.modal-container {display: grid;align-items: center;position: fixed;height: 100vh;z-index: 999;left: 10px;top: -100px;justify-items: end;}
.modal-background {position: absolute;background: rgba(9, 8, 8, 19%);left:0;top:0;z-index: 999;width: 100%;height: 100vh;}
.modal-page {border-radius: 0.3rem;z-index: 9999;background-color: rgba(28, 35, 71, 1);width: 330px;max-width: 20%;padding: 5px;}
.delete-pop-up{background-color: rgb(61, 180, 235);}
.delete-button{display: grid;grid-template-columns: auto auto;}
.no-data-found{text-align: center;position: relative;top: 100px;}
.no-challan-found{color: #FFF; text-align: center;background: rgba(69, 73, 102, 1);padding: 40px;}
.no-challan-found img{margin-bottom: 25px;}
.no-challan-found h3{margin-bottom: 15px;}
.delete-icons{font-size: 26px;}
.action-data{display: grid;grid-template-columns: 1fr auto;gap: 113px;}