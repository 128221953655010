.header{color: #fff;background: linear-gradient(360deg, #e38282 0%, rgba(40, 30, 29, 1) 100%);padding: 20px 30px;border-radius: 0px 0px 50px 50px;margin-bottom: 30px;}
.header-image{margin-top: 25px;text-align: center;}
.vehicle-flow-header{background-color:rgba(244, 118, 115, 0.3);background: linear-gradient(180deg, #000000 0%, #ffffff00 100%);}
.search-details {display: grid; grid-template-columns: auto auto;gap: 5px;}
.search-details button{border-radius: 15px;}
.enter-vehicle-details{padding: 20px;color: #fff;}
.search-details {border: 1px solid #FFF;border-radius: 10rem;padding: 10px;}
.search-details input {border: 0;margin: 0;text-transform: uppercase;font-size: 18px;}
.search-details input:focus {outline: none;}  
.search-details button {border-radius: 5rem;}
.history-header{background: linear-gradient(360deg, #e38282 0%, rgba(40, 30, 29, 1) 100%);padding: 30px 30px 50px 30px;border-radius: 0px 0px 50px 50px;margin-bottom: 30px;}
.history-header span{margin-top: 50px;}
.vehicle-complete-info{display: grid;grid-template-columns: auto auto;align-items: center;justify-content: center;gap: 25px;color: #FFF;margin-top: 40px;}
.vehicle-complete-info p{font-size: 24px; font-weight: 600;}
.vehicle-complete-info span{font-size: 12px;}
.vehicle-blur-data{margin-top: -25px;}
.vehicle-final-data{border: 1px solid #FFFFFF29;background: #454966;padding: 10px;color: #FFF;border-radius: 10px;}
.vehicle-final-data p{border-bottom: 1px solid #FFFFFF29;padding: 10px;}
.vehicle-final-data >p:last-child {border-bottom: none;}
.vehicle-final-data span{float: right;}
.history{padding:15px 15px 15px 10px;color:#FFF;}
.blur-content {text-align: center;color: #FFF;position: absolute;width: calc(100vw - 50px);z-index: 99;height: calc(39px * 9);display: grid;place-items: center;align-content: center;}
.blur-content p {margin: 5px 0 15px 0;border-bottom: 0;font-size: 20px;}
.blur-content button {width: auto;}
.vehicle-container {position: fixed;height: 100vh;width: 100%;display: grid;align-items: center;justify-content: center;z-index: 999; left: 0; top: 0;}
.vehicle-background {position: absolute;background: rgba(9, 8, 8, 0.83);left:0;top:0;z-index: 999;width: 100%;height: 100vh;}
.vehicle-page {border-radius: 0.3rem; z-index: 9999; background-color: rgba(28, 35, 71, 1);width: 330px;max-width: 100%;padding: 25px 25px 50px 25px;}
.verify-and-cancel{display: grid; grid-template-columns: auto auto; color: #FFF;margin-bottom: 15px;justify-content: space-between;}
.verify-and-cancel div{font-size: 20px;}
/* .blur-box{background-image: url('./vehicle-blur-detail.svg');} */
.back-option{color: #FFF;}
.history-header{box-shadow: 0px 4px 55.3px 0px rgba(244, 118, 115, 0.3);}
.back-button a{color: #fff; text-decoration: none;}
.header span{font-weight: 600; font-size: 18px;padding-left: 5px;}
.input-detail{padding: 20px;}